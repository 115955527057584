import { component, observable } from 'knockout-decorators'

import { Background, ImageProcessStep } from '../../pages/background'
import { pageState } from '~/store/page'

import source from './subpage-header.html?raw'
import './subpage-header.less'

interface Params {
  image?: string
  logo?: string
  nobg?: boolean
  name?: string
  null?: null
}
@component('subpage-header', source)
export class SubPageHeader {
  @observable public imageUrl = ''
  @observable public logoUrl = ''
  @observable public title = ''

  @observable public hasImage = false
  @observable public hasLogo = false

  private background = Background.getInstance()
  private setBackground = true

  constructor(params: Params) {
    if (params.image && params.image !== null && params.image.length > 0) {
      this.imageUrl = params.image
      this.hasImage = true
    }

    if (params.logo && params.null !== null) {
      this.logoUrl = params.logo
      this.hasLogo = true
    }

    if (params.nobg) {
      this.setBackground = false
    }

    this.title = params.name ? params.name : pageState.config.title
  }

  private async imageLoaded(image: HTMLImageElement) {
    if (!this.setBackground) {
      return
    }
    await this.background.addCanvasBackground(this.imageUrl, image, [
      ImageProcessStep.original,
      ImageProcessStep.colorOverlay,
      ImageProcessStep.blur,
      ImageProcessStep.shadow,
    ])
    this.background.setCanvasBackground(this.imageUrl)
  }
}
